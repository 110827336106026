import { useEffect, useState } from 'react';

export default function useWindowDimensions(): Dimensions {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function getWindowDimensions(): Dimensions {
  const { innerWidth, innerHeight } = window;
  return { width: innerWidth, height: innerHeight };
}
