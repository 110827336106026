import { motion, MotionProps } from 'framer-motion';
import { forwardRef, PropsWithChildren } from 'react';
import cn from 'classnames';

const Page = forwardRef<
  HTMLElement,
  PropsWithChildren<MotionProps & { className?: string }>
>(({ children, className, ...rest }, ref) => (
  <motion.section
    ref={ref}
    className={cn(
      'h-screen flex justify-center items-center relative snap-center',
      className,
    )}
    {...rest}
  >
    {children}
  </motion.section>
));

export default Page;
