import Page from 'components/Page/Page';
import { motion, useMotionValueEvent, useScroll } from 'framer-motion';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useState } from 'react';

export default function Title() {
  const { height: windowHeight } = useWindowDimensions();
  const [headerHeight, setHeaderHeight] = useState(INITIAL_HEADER_HEIGHT_VH);
  const [top, setTop] = useState(windowHeight / 2);
  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, 'change', latestScrollY => {
    const top = Math.max(0, windowHeight / 2 - latestScrollY);
    setTop(top);
    setHeaderHeight(
      MINIMUM_HEADER_HEIGHT_VH +
        (top / (windowHeight / 2)) *
          (INITIAL_HEADER_HEIGHT_VH - MINIMUM_HEADER_HEIGHT_VH),
    );
  });

  return (
    <>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ type: 'spring', stiffness: 100, delay: 0.5 }}
        className="fixed w-screen z-30 py-4"
        style={{
          top: `calc(${top}px - ${
            (headerHeight / 2) * (top / (windowHeight / 2))
          }vh)`,
          height: `${headerHeight}vh`,
        }}
      >
        <picture className="flex items-center justify-center w-screen h-full">
          <source srcSet="images/UNKNOWN.png" />
          <img
            className="w-auto h-auto max-w-[70vw] max-h-full"
            src="/images/UNKNOWN.webp"
            alt="logo"
          />
        </picture>
      </motion.div>
      <Page></Page>
    </>
  );
}

const INITIAL_HEADER_HEIGHT_VH = 15;
const MINIMUM_HEADER_HEIGHT_VH = 8;
