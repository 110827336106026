import { motion, useScroll } from 'framer-motion';

export default function ProgressBar() {
  const { scrollYProgress } = useScroll();

  return (
    <motion.div
      className="fixed inset-x-0 bottom-0 h-4 bg-neutral-50 origin-[0%]"
      style={{ scaleX: scrollYProgress }}
    />
  );
}
