import { motion, Variants } from 'framer-motion';
import Page from 'components/Page/Page';

interface Props {
  photo: Photo;
}
export default function ImageListItem({ photo }: Props) {
  const imageVariants: Variants = {
    offscreen: {
      opacity: 0,
    },
    onscreen: {
      opacity: 1,
      transition: {
        type: 'tween',
        ease: 'easeOut',
      },
    },
  };

  return (
    <Page>
      <div className="relative w-screen h-[90vh] overflow-hidden">
        <motion.picture
          className="w-full h-full flex justify-center items-center"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.5 }}
          variants={imageVariants}
        >
          <source
            srcSet={`${photo.thumbnail[2160]} 1080w, ${photo.thumbnail[1080]} 540w`}
          />
          <img
            src={photo.thumbnail[2160]}
            alt="gallery"
            className="w-auto max-h-[50%] rounded-2xl border-white/10 border-[1px]"
          />
        </motion.picture>
      </div>
    </Page>
  );
}
