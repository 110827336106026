import getImagePath from 'function';
import ImageList from 'components/ImageList/ImageList';
import ImageListItem from 'components/ImageList/ImageListItem';
import './index.scss';
import Title from 'components/Title/Title';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import { useEffect } from 'react';
import { motion } from 'framer-motion';

export default function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  return (
    <div className="relative w-screen min-h-screen flex flex-col items-center">
      <motion.div
        className="fixed inset-0"
        animate={{
          background: [
            'linear-gradient(60deg, #1f1f1f, #000)',
            'linear-gradient(60deg, #000, #1f1f1f)',
          ],
        }}
        transition={{
          duration: 5,
          repeat: Infinity,
          repeatType: 'reverse',
        }}
      />
      <Title />
      <ImageList>
        {[
          { thumbnail: getImagePath('green1') },
          { thumbnail: getImagePath('green2') },
          { thumbnail: getImagePath('green3') },
          { thumbnail: getImagePath('green4') },
        ].map(photo => (
          <ImageListItem key={photo.thumbnail[1080]} photo={photo} />
        ))}
      </ImageList>
      <ProgressBar />
    </div>
  );
}
