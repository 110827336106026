import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GROUP_LIST } from 'constant';

export interface LayoutState {
  isTop: boolean;
  selectedGroup: Group;
}

const initialState: LayoutState = {
  isTop: true,
  selectedGroup: GROUP_LIST[0],
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setIsTop: (state, action: PayloadAction<boolean>) => {
      state.isTop = action.payload;
    },
    selectGroup: (state, action: PayloadAction<Group>) => {
      state.selectedGroup = action.payload;
    },
  },
});

export const { setIsTop, selectGroup } = layoutSlice.actions;

export default layoutSlice.reducer;
